@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --swiper-navigation-size: 20px !important;
}

.swiper-button-prev,
.swiper-button-next {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.65);
  transition: all 300ms;
  border-radius: 50%;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(255, 255, 255, 0.95);
}

@media(max-width: 465px) {
  :root {
    --swiper-navigation-size: 12px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 0.85rem;
  }
}

.bg-navbar {
  background-color: #292d33;
}

body {
  background-color: #292d33;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  scroll-behavior: smooth;
}

::selection {
  background: #0000004c;
}