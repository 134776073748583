.dropdown::-webkit-scrollbar {
    width: 7px;
}

.dropdown::-webkit-scrollbar-track {
    background-color: #434343;
    border-radius: 100px;
}

.dropdown::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #cacacadb 0%, #ffffffa8 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}