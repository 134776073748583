.loader_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #34363e;
}

.cube {
    position: absolute;
    top: 80px;
    left: 80px;
    width: 1em;
    height: 1em;
    background: #eba1ff;
    -webkit-animation: move 3s ease-in-out infinite;
    animation: move 3s ease-in-out infinite;
    transform-style: preserve-3d;
    box-shadow: 5em 5em 0.5em 0.05em #99999943;
}

.cube::before, .cube::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
}

.cube::before {
    background-color: #88349f;
    transform-origin: 100% 100%;
    transform: rotateY(-90deg);
}

.cube::after {
    background-color: #C778DD;
    transform-origin: 0% 100%;
    transform: rotateX(90deg);
}

.cube:nth-of-type(1) {
    -webkit-animation-delay: -11.25s;
    animation-delay: -11.25s;
}

.cube:nth-of-type(2) {
    -webkit-animation-delay: -10.5s;
    animation-delay: -10.5s;
}

.cube:nth-of-type(3) {
    -webkit-animation-delay: -9.75s;
    animation-delay: -9.75s;
}

.cube:nth-of-type(4) {
    -webkit-animation-delay: -9s;
    animation-delay: -9s;
}

@-webkit-keyframes move {
    0%, 87.5%, 100% {
        transform: translate(1em, 0em);
    }

    12.5% {
        transform: translate(2em, 0em);
    }

    25% {
        transform: translate(2em, 1em);
    }

    37.5%, 50% {
        transform: translate(1em, 1em);
    }

    62.5% {
        transform: translate(0em, 1em);
    }

    75% {
        transform: translate(0em, 0em);
    }
}

@keyframes move {
    0%, 87.5%, 100% {
        transform: translate(1em, 0em);
    }

    12.5% {
        transform: translate(2em, 0em);
    }

    25% {
        transform: translate(2em, 1em);
    }

    37.5%, 50% {
        transform: translate(1em, 1em);
    }

    62.5% {
        transform: translate(0em, 1em);
    }

    75% {
        transform: translate(0em, 0em);
    }
}

.box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 3em;
    height: 2em;
    margin: 30vmin auto;
    font-size: 50px;
    transform-style: preserve-3d;
    transform: rotateX(60deg) rotateZ(45deg);
}

.box p {
    color: rgba(255, 255, 255, 0.75);
    font-size: 2.5rem;
    margin-top: 3rem;
}

@media (max-width: 475px) {
    .box {
        top: 180px;
    }
}

@media (max-width: 400px) {
    .box {
        top: 100px;
    }
}